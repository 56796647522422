import { gql } from "graphql-tag";

export const userDeviceFragment = gql`
	fragment userDeviceFragment on UserDevice {
		id
		fcmToken
		lastUsed
		owner {
						id
					}
	}
`;
