import { gql } from "graphql-tag";
import { addressFragment } from "../../addresses/graphql/address.fragment";
import { sharedContactPersonFragment } from "../../shared-contact-people/graphql/shared-contact-person.fragment";

export const companyPartnerFragment = gql`
  ${addressFragment}
  ${sharedContactPersonFragment}
  fragment companyPartnerFragment on CompanyPartner {
    id
    name
    addresses {
      ...addressFragment
    }
    businessId
    taxId
    vatId
    iban
    bankCode
    contacts {
      ...sharedContactPersonFragment
    }
  }
`;
