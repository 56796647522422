import { gql } from "graphql-tag";

export const addressFragment = gql`
	fragment addressFragment on Address {
		id
		name
		street
		city
		zip
		phone
		mobile
		email
		country
	}
`;
