import { graphqlApi } from "@/lib/api/graphqlApi";
import { GET_SINGLE_USER_DEVICE_QUERY, GET_USER_DEVICES_QUERY } from "../graphql/user-device.queries";
import { UpdateUserDeviceMutation, CreateUserDeviceMutation, DeleteUserDeviceMutation } from "../graphql/user-device.mutations";
import { CollectionQueryInput } from "@/lib/features/shared/model/collection-query-input.model";
import { UserDevice } from "../model/user-device.model";
import { GetSingleUserDeviceReturn, GetUserDeviceCollectionReturn } from "../model/actions/user-device-read.model";
import { UpdateUserDeviceReturn, UpdateUserDeviceInput } from "../model/actions/user-device-update.model";
import { CreateUserDeviceInput, CreateUserDeviceReturn } from "../model/actions/user-device-create.model";
import { DeleteUserDeviceInput, DeleteUserDeviceReturn } from "../model/actions/user-device-delete.model";


/** Api method to create a user device */
export const createUserDevice = async (payload: CreateUserDeviceInput): Promise<UserDevice> => {
  const { data: { createUserDevice: { userDevice } } } = await graphqlApi<CreateUserDeviceReturn>(CreateUserDeviceMutation, {
    variables: {
      input: payload
    }
  })

  return userDevice
};

/** Api method to fetch a single user device */
export const getSingleUserDevice = async (id: number): Promise<UserDevice> => {
  const { data: { userDevice } } = await graphqlApi<GetSingleUserDeviceReturn>(GET_SINGLE_USER_DEVICE_QUERY, {
    variables: {
      id
    }
  })

  return userDevice
};

/** Api method to fetch multiple user_devices in a collection with all the filters specified in queryInput applied */
export const getUserDeviceCollection = async (queryInput: CollectionQueryInput) => {
  const { data } = await graphqlApi<GetUserDeviceCollectionReturn>(GET_USER_DEVICES_QUERY, {
    variables: queryInput
  })
  return data;
};

/** Api method to update user device */
export const updateUserDevice = async (updatePayload: UpdateUserDeviceInput): Promise<UserDevice> => {
  const { data: {
    updateUserDevice: { userDevice }
  } } = await graphqlApi<UpdateUserDeviceReturn>(UpdateUserDeviceMutation, {
    variables: {
      input: updatePayload
    }
  })

  return userDevice
};

/** Api method to delete user device */
export const deleteUserDevice = async (deleteInput: DeleteUserDeviceInput): Promise<UserDevice> => {
  const { data: {
    deleteUserDevice: { userDevice }
  } } = await graphqlApi<DeleteUserDeviceReturn>(DeleteUserDeviceMutation, {
    variables: {
      input: deleteInput
    }
  })

  return userDevice
};
