import { gql } from "graphql-tag";
import { companyPartnerFragment } from "./company-partner.fragment";

// Mutation to create companyPartner
export const CreateCompanyPartnerMutation = gql`
	${companyPartnerFragment}
	mutation createCompanyPartnerMutation($input: createCompanyPartnerInput) {
		createCompanyPartner(input: $input) {
			companyPartner {
				...companyPartnerFragment
			}
		}
	}
`;

// Mutation to update companyPartner
export const UpdateCompanyPartnerMutation = gql`
	${companyPartnerFragment}
	mutation updateCompanyPartnerMutation($input: updateCompanyPartnerInput) {
		updateCompanyPartner(input: $input) {
			companyPartner {
				...companyPartnerFragment
			}
		}
	}
`;

// Mutation to delete companyPartner
export const DeleteCompanyPartnerMutation = gql`
	${companyPartnerFragment}
	mutation deleteCompanyPartnerMutation($input: deleteCompanyPartnerInput) {
		deleteCompanyPartner(input: $input) {
			companyPartner {
				...companyPartnerFragment
			}
		}
	}
`;
