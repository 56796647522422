import { gql } from "graphql-tag";

export const sharedContactPersonFragment = gql`
	fragment sharedContactPersonFragment on SharedContactPerson {
		id
		name
		position
		phone
	}
`;
