import { InvoiceTable } from "@/lib/features/invoices/components/InvoiceTable/InvoiceTable";
import { userSelector } from "@/store/slices/authSlice";
import React from "react";
import { useSelector } from "react-redux";

export interface UnapprovedInvoicesProps {}

export const UnapprovedInvoices = (props: UnapprovedInvoicesProps) => {
  const {} = props;
  const user = useSelector(userSelector);
  return (
    <InvoiceTable
      predefinedFilters={{
        approvalProcessStatus: "inProgress",
        approvalProcessWaitingOn: user?.id,
      }}
      summary={null}
      search={false}
      cardProps={{
        title: "Faktúry na schválenie",
      }}
    />
  );
};
