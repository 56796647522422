import { removeAuthHeader, setAuthHeader } from "@/lib/api/graphqlApi";
import { deAuthUser, setAuth } from "@/store/slices/authSlice";
import { Dispatch } from "@reduxjs/toolkit";
import { LOCALSTORAGE_KEY_AUTH } from "../consts/auth-localstorage";
import { AuthReturn } from "./../model/auth.model";
import { unregisterRegisteredUserDevice } from "./logout.utils";

export const performClientSideLogin = (
  auth: AuthReturn,
  dispatch: Dispatch<any>,
  storeInLocalStorage: boolean = true
) => {
  dispatch(setAuth(auth));

  if (storeInLocalStorage) {
    localStorage.setItem(LOCALSTORAGE_KEY_AUTH, JSON.stringify(auth));
  }

  setAuthHeader(auth.jwt);
};

export const performClientSideLogout = (dispatch: Dispatch<any>) => {
  dispatch(deAuthUser());
  unregisterRegisteredUserDevice();
  localStorage.removeItem(LOCALSTORAGE_KEY_AUTH);
  removeAuthHeader();
};
