import { gql } from "graphql-tag";
import { companyPartnerFragment } from "./company-partner.fragment";

// Query to retrieve single companyPartner
export const GET_SINGLE_COMPANY_PARTNER_QUERY = gql`
	${companyPartnerFragment}
	query getSingleCompanyPartnerQuery($id: ID!) {
		companyPartner(id: $id) {
			...companyPartnerFragment
		}
	}
`;

// Query to retrieve multiple company_partners
export const GET_COMPANY_PARTNERS_QUERY = gql`
	${companyPartnerFragment}
	query getCompanyPartnersQuery(
		$sort: String = "id:desc"
		$limit: Int
		$start: Int
		$where: JSON
	) {
		companyPartners(sort: $sort, limit: $limit, start: $start, where: $where) {
			...companyPartnerFragment
		}

		companyPartnersCount(where: $where)
	}
`;
