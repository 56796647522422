import { graphqlApi } from "@/lib/api/graphqlApi";
import { CollectionQueryInput } from "@/lib/features/shared/model/collection-query-input.model";
import {
  CreateCompanyPartnerMutation,
  DeleteCompanyPartnerMutation,
  UpdateCompanyPartnerMutation,
} from "../graphql/company-partner.mutations";
import {
  GET_COMPANY_PARTNERS_QUERY,
  GET_SINGLE_COMPANY_PARTNER_QUERY,
} from "../graphql/company-partner.queries";
import {
  CreateCompanyPartnerInput,
  CreateCompanyPartnerReturn,
} from "../model/actions/company-partner-create.model";
import {
  DeleteCompanyPartnerInput,
  DeleteCompanyPartnerReturn,
} from "../model/actions/company-partner-delete.model";
import {
  GetCompanyPartnerCollectionReturn,
  GetSingleCompanyPartnerReturn,
} from "../model/actions/company-partner-read.model";
import {
  UpdateCompanyPartnerInput,
  UpdateCompanyPartnerReturn,
} from "../model/actions/company-partner-update.model";
import { CompanyPartner } from "../model/company-partner.model";

/** Api method to create a company partner */
export const createCompanyPartner = async (
  payload: CreateCompanyPartnerInput
): Promise<CompanyPartner> => {
  const {
    data: {
      createCompanyPartner: { companyPartner },
    },
  } = await graphqlApi<CreateCompanyPartnerReturn>(
    CreateCompanyPartnerMutation,
    {
      variables: {
        input: payload,
      },
    }
  );

  return companyPartner;
};

/** Api method to fetch a single company partner */
export const getSingleCompanyPartner = async (
  id: number
): Promise<CompanyPartner> => {
  const {
    data: { companyPartner },
  } = await graphqlApi<GetSingleCompanyPartnerReturn>(
    GET_SINGLE_COMPANY_PARTNER_QUERY,
    {
      variables: {
        id,
      },
    }
  );

  return companyPartner;
};

/** Api method to fetch multiple company_partners in a collection with all the filters specified in queryInput applied */
export const getCompanyPartnerCollection = async (
  queryInput: CollectionQueryInput
) => {
  const { data } = await graphqlApi<GetCompanyPartnerCollectionReturn>(
    GET_COMPANY_PARTNERS_QUERY,
    {
      variables: queryInput,
    }
  );
  return data;
};

/** Api method to update company partner */
export const updateCompanyPartner = async (
  updatePayload: UpdateCompanyPartnerInput,
  headers?: object
): Promise<CompanyPartner> => {
  const {
    data: {
      updateCompanyPartner: { companyPartner },
    },
  } = await graphqlApi<UpdateCompanyPartnerReturn>(
    UpdateCompanyPartnerMutation,
    {
      variables: {
        input: updatePayload,
      },
      requestHeaders: headers,
    }
  );

  return companyPartner;
};

/** Api method to delete company partner */
export const deleteCompanyPartner = async (
  deleteInput: DeleteCompanyPartnerInput
): Promise<CompanyPartner> => {
  const {
    data: {
      deleteCompanyPartner: { companyPartner },
    },
  } = await graphqlApi<DeleteCompanyPartnerReturn>(
    DeleteCompanyPartnerMutation,
    {
      variables: {
        input: deleteInput,
      },
    }
  );

  return companyPartner;
};
