import { queryClient } from "@/lib/react-query/client";
import { useQuery, UseQueryOptions } from "react-query";
import { getSingleCompanyPartner } from "../api/company-partner.api";
import { USE_SINGLE_COMPANY_PARTNER_QUERY_KEY } from "../consts/company-partner.query-keys";
import { CompanyPartner } from "../model/company-partner.model";

export const prefetchSingleCompanyPartnerQuery = async (id: number) => {
  await queryClient.prefetchQuery(
    [
      USE_SINGLE_COMPANY_PARTNER_QUERY_KEY,
      { id: typeof id === "number" ? id : parseInt(id) },
    ],
    () => getSingleCompanyPartner(id)
  );
};
export const useSingleCompanyPartnerQuery = (
  id: number,
  options: UseQueryOptions<CompanyPartner>
) => {
  return useQuery<CompanyPartner>(
    [
      USE_SINGLE_COMPANY_PARTNER_QUERY_KEY,
      { id: typeof id === "number" ? id : parseInt(id) },
    ],
    () => getSingleCompanyPartner(id),
    {
      enabled: !!id,
      ...options,
    }
  );
};
