import { gql } from "graphql-tag";
import { userDeviceFragment } from "./user-device.fragment";

// Mutation to create userDevice
export const CreateUserDeviceMutation = gql`
	${userDeviceFragment}
	mutation createUserDeviceMutation($input: createUserDeviceInput) {
		createUserDevice(input: $input) {
			userDevice {
				...userDeviceFragment
			}
		}
	}
`;

// Mutation to update userDevice
export const UpdateUserDeviceMutation = gql`
	${userDeviceFragment}
	mutation updateUserDeviceMutation($input: updateUserDeviceInput) {
		updateUserDevice(input: $input) {
			userDevice {
				...userDeviceFragment
			}
		}
	}
`;

// Mutation to delete userDevice
export const DeleteUserDeviceMutation = gql`
	${userDeviceFragment}
	mutation deleteUserDeviceMutation($input: deleteUserDeviceInput) {
		deleteUserDevice(input: $input) {
			userDevice {
				...userDeviceFragment
			}
		}
	}
`;
