import { gql } from "graphql-tag";
import { userDeviceFragment } from "./user-device.fragment";

// Query to retrieve single userDevice
export const GET_SINGLE_USER_DEVICE_QUERY = gql`
	${userDeviceFragment}
	query getSingleUserDeviceQuery($id: ID!) {
		userDevice(id: $id) {
			...userDeviceFragment
		}
	}
`;

// Query to retrieve multiple user_devices
export const GET_USER_DEVICES_QUERY = gql`
	${userDeviceFragment}
	query getUserDevicesQuery(
		$sort: String = "id:desc"
		$limit: Int
		$start: Int
		$where: JSON
	) {
		userDevices(sort: $sort, limit: $limit, start: $start, where: $where) {
			...userDeviceFragment
		}

		userDevicesCount(where: $where)
	}
`;
