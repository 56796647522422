import * as Sentry from "@sentry/nextjs";
import { print } from "graphql";
import { api } from "./api";
import { handleGraphqlErrors } from "./utils";

export interface GraphqlApiFunctionOptions {
  variables: object;
  requestHeaders?: object;
}

export const graphqlApi = async <T>(
  query,
  { variables = {}, requestHeaders }: GraphqlApiFunctionOptions = {
    variables: {},
  }
): Promise<{
  data: T;
  headers: any;
}> => {
  try {
    const {
      data: { data, errors },
      headers,
    } = await api.request<{ data: T; errors?: any }>({
      url: `/graphql`,
      method: "POST",
      data: {
        query: print(query),
        variables,
      },
      headers: requestHeaders as any,
    });

    Sentry.addBreadcrumb({
      message: "GraphQL query",
      data: {
        query: print(query),
        variables,
      },
    });

    if (errors) {
      handleGraphqlErrors(errors);
    }
    return {
      data,
      headers,
    };
  } catch (e) {
    throw e;
  }
};

export const setAuthHeader = (token: string) => {
  api.defaults.headers.common["authorization"] = "Bearer " + token;
};

export const removeAuthHeader = () => {
  const { authorization, ...rest } = api.defaults.headers.common;
  api.defaults.headers.common = rest;
};

export { api };
